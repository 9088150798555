var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"field"},[_c('SODSelect',{attrs:{"strategies":_vm.$store.state.strats.strategies.porovt,"controlLabel":"SOD Determining If Allowed*","nullValueLabel":"Select POROVT SOD"},model:{value:(_vm.ic.overTimePOROVT),callback:function ($$v) {_vm.$set(_vm.ic, "overTimePOROVT", $$v)},expression:"ic.overTimePOROVT"}})],1)])]),_c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"field"},[_c('SODSelect',{attrs:{"strategies":_vm.parrngStrategies,"controlLabel":"SOD Called On Submit*","nullValueLabel":"Select PARRNG SOD"},model:{value:(_vm.ic.negotiatorPARRNG),callback:function ($$v) {_vm.$set(_vm.ic, "negotiatorPARRNG", $$v)},expression:"ic.negotiatorPARRNG"}})],1)])]),_c('option-card',{attrs:{"titleLabel":"Over Time","p1":_vm.ic.overTimeTitle,"p2":_vm.ic.overTimeContent,"p3":_vm.ic.overTimeButton,"label1":"Over Time Title*","label2":"Over Time Content*","label3":"Over Time Button*","imgSrc":"@/assets/over_time_guide.png","imgAlt":"Over Time Guide","passedRules":_vm.overTimeRuleObj,"emitName":"over-time-updated"},on:{"over-time-updated":_vm.updateOverTime}}),_c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Offer Title*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.offerTitle),expression:"ic.offerTitle"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Create your offer to creditor","name":"Over Time Offer Title"},domProps:{"value":(_vm.ic.offerTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "offerTitle", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(" Offer Content*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.offerContent),expression:"ic.offerContent"}],staticClass:"textarea clob",attrs:{"placeholder":"e.g. This company will consider reasonable offers","name":"Over Time Offer Content"},domProps:{"value":(_vm.ic.offerContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "offerContent", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Arranged Amount Label*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.overTimeAmountLabel),expression:"ic.overTimeAmountLabel"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Arranged Amount","name":"Arranged Amount Label"},domProps:{"value":(_vm.ic.overTimeAmountLabel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "overTimeAmountLabel", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Afford Label*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.affordLabel),expression:"ic.affordLabel"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. How much can you afford monthly?","name":"Afford Label"},domProps:{"value":(_vm.ic.affordLabel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "affordLabel", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Afford Small")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.affordSmall),expression:"ic.affordSmall"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Before submitting an offer, be sure you can afford the monthly amount.","name":"Afford Small"},domProps:{"value":(_vm.ic.affordSmall)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "affordSmall", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Number Label*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.numberLabel),expression:"ic.numberLabel"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Number of monthly payments","name":"Number Label"},domProps:{"value":(_vm.ic.numberLabel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "numberLabel", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Number Small")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.numberSmall),expression:"ic.numberSmall"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Up to 6 months allowed by your creditor","name":"Number Small"},domProps:{"value":(_vm.ic.numberSmall)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "numberSmall", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("First Label*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.firstLabel),expression:"ic.firstLabel"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. First payment","name":"First Label"},domProps:{"value":(_vm.ic.firstLabel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "firstLabel", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("First Small")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.firstSmall),expression:"ic.firstSmall"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. You can defer your first payment","name":"First Small"},domProps:{"value":(_vm.ic.firstSmall)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "firstSmall", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("@/assets/create_offer_guide.png"),"alt":"Create Offer Specification"}})])])])]),_c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Not Approved Header*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.notApprovedHeader),expression:"ic.notApprovedHeader"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. You'll need to specify","name":"Not Approved Header"},domProps:{"value":(_vm.ic.notApprovedHeader)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "notApprovedHeader", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Not Approved Amount*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.notApprovedAmount),expression:"ic.notApprovedAmount"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. A minimum amount of","name":"Not Approved Amount"},domProps:{"value":(_vm.ic.notApprovedAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "notApprovedAmount", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Not Approved Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.notApprovedNumber),expression:"ic.notApprovedNumber"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. A minimum number of payments of","name":"Not Approved Number"},domProps:{"value":(_vm.ic.notApprovedNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "notApprovedNumber", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Not Approved First*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.notApprovedFirst),expression:"ic.notApprovedFirst"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. And a date no later than","name":"Not Approved First"},domProps:{"value":(_vm.ic.notApprovedFirst)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "notApprovedFirst", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Not Approved Button*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.notApprovedButton),expression:"ic.notApprovedButton"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. Try These Values","name":"Not Approved Button"},domProps:{"value":(_vm.ic.notApprovedButton)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "notApprovedButton", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("@/assets/over_time_not_approved.png"),"alt":"Not Approved Panel Customization"}})])])])]),_c('div',{staticClass:"field"},[_c('p',{staticClass:"content"},[_c('em',[_vm._v("* indicates required field")])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }