<template>
  <validation-observer v-slot="{ invalid }" class="container">
    <div class="card field">
      <div class="card-content">
        <div class="field">
          <SODSelect
            v-model="ic.overTimePOROVT"
            :strategies="$store.state.strats.strategies.porovt"
            controlLabel="SOD Determining If Allowed*"
            nullValueLabel="Select POROVT SOD"
          />
        </div>
      </div>
    </div>

    <div class="card field">
      <div class="card-content">
        <div class="field">
          <SODSelect
            v-model="ic.negotiatorPARRNG"
            :strategies="parrngStrategies"
            controlLabel="SOD Called On Submit*"
            nullValueLabel="Select PARRNG SOD"
          />
        </div>
      </div>
    </div>

    <option-card
      titleLabel="Over Time"
      :p1="ic.overTimeTitle"
      :p2="ic.overTimeContent"
      :p3="ic.overTimeButton"
      label1="Over Time Title*"
      label2="Over Time Content*"
      label3="Over Time Button*"
      imgSrc="@/assets/over_time_guide.png"
      imgAlt="Over Time Guide"
      :passedRules="overTimeRuleObj"
      emitName="over-time-updated"
      @over-time-updated="updateOverTime"
    />

    <div class="card field">
      <div class="card-content">
        <div class="columns">
          <div class="column is-one-third">
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Offer Title*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Create your offer to creditor"
                  v-model="ic.offerTitle"
                  name="Over Time Offer Title"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:1000000"
            >
              <div class="control">
                <label class="label"> Offer Content*</label>
                <textarea
                  class="textarea clob"
                  placeholder="e.g. This company will consider reasonable offers"
                  v-model="ic.offerContent"
                  name="Over Time Offer Content"
                ></textarea>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Arranged Amount Label*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Arranged Amount"
                  v-model="ic.overTimeAmountLabel"
                  name="Arranged Amount Label"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Afford Label*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. How much can you afford monthly?"
                  v-model="ic.affordLabel"
                  name="Afford Label"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="max:255"
            >
              <div class="control">
                <label class="label">Afford Small</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Before submitting an offer, be sure you can afford the monthly amount."
                  v-model="ic.affordSmall"
                  name="Afford Small"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Number Label*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Number of monthly payments"
                  v-model="ic.numberLabel"
                  name="Number Label"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="max:255"
            >
              <div class="control">
                <label class="label">Number Small</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Up to 6 months allowed by your creditor"
                  v-model="ic.numberSmall"
                  name="Number Small"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">First Label*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. First payment"
                  v-model="ic.firstLabel"
                  name="First Label"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="max:255"
            >
              <div class="control">
                <label class="label">First Small</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. You can defer your first payment"
                  v-model="ic.firstSmall"
                  name="First Small"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
          </div>
          <div class="column">
            <img
              src="@/assets/create_offer_guide.png"
              alt="Create Offer Specification"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card field">
      <div class="card-content">
        <div class="columns">
          <div class="column is-one-third">
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Not Approved Header*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. You'll need to specify"
                  v-model="ic.notApprovedHeader"
                  name="Not Approved Header"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Not Approved Amount*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. A minimum amount of"
                  v-model="ic.notApprovedAmount"
                  name="Not Approved Amount"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Not Approved Number*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. A minimum number of payments of"
                  v-model="ic.notApprovedNumber"
                  name="Not Approved Number"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Not Approved First*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. And a date no later than"
                  v-model="ic.notApprovedFirst"
                  name="Not Approved First"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              rules="required|max:255"
            >
              <div class="control">
                <label class="label">Not Approved Button*</label>
                <input
                  type="text"
                  class="input"
                  placeholder="e.g. Try These Values"
                  v-model="ic.notApprovedButton"
                  name="Not Approved Button"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </div>
            </validation-provider>
          </div>
          <!-- column -->
          <div class="column">
            <img
              src="@/assets/over_time_not_approved.png"
              alt="Not Approved Panel Customization"
            />
          </div>
          <!-- column -->
        </div>
        <!-- columns -->
      </div>
      <!-- card-content -->
    </div>
    <!-- card -->
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SODSelect from "@/components/SODSelect.vue";
import { mapState, mapActions } from "vuex";
import OptionCard from "@/components/instance/OptionCard.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SODSelect,
    OptionCard
  },
  data() {
    return {
      ic: {
        instanceId: null,
        offerTitle: null,
        offerContent: null,
        affordLabel: null,
        affordSmall: null,
        numberLabel: null,
        numberSmall: null,
        firstLabel: null,
        firstSmall: null,
        negotiatorPARRNG: null,
        overTimeAmountLabel: null,
        notApprovedHeader: null,
        notApprovedAmount: null,
        notApprovedNumber: null,
        notApprovedFirst: null,
        notApprovedButton: null,
        overTimePOROVT: null
      },
      overTimeRuleObj: { required: true, max: { length: 255 } }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchPARRNGStrategies();
        await vm.fetchInstances();
        await vm.$store.dispatch("strats/fetchStrategies", "POROVT");
        vm.loadView.call(vm, to.params.id);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceOverTime; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig",
      "fetchPARRNGStrategies"
    ]),
    loadView(id) {
      this.ic.instanceId = id;
      this.ic.offerTitle = this.$store.state.instanceConfig.offerTitle;
      this.ic.offerContent = this.$store.state.instanceConfig.offerContent;
      this.ic.affordLabel = this.$store.state.instanceConfig.affordLabel;
      this.ic.affordSmall = this.$store.state.instanceConfig.affordSmall;
      this.ic.numberLabel = this.$store.state.instanceConfig.numberLabel;
      this.ic.numberSmall = this.$store.state.instanceConfig.numberSmall;
      this.ic.firstLabel = this.$store.state.instanceConfig.firstLabel;
      this.ic.firstSmall = this.$store.state.instanceConfig.firstSmall;
      this.ic.negotiatorPARRNG = this.$store.state.instanceConfig.negotiatorPARRNG;
      this.ic.overTimeTitle = this.$store.state.instanceConfig.overTimeTitle;
      this.ic.overTimeContent = this.$store.state.instanceConfig.overTimeContent;
      this.ic.overTimeButton = this.$store.state.instanceConfig.overTimeButton;
      this.ic.overTimeAmountLabel = this.$store.state.instanceConfig.overTimeAmountLabel;
      this.ic.notApprovedHeader = this.$store.state.instanceConfig.notApprovedHeader;
      this.ic.notApprovedAmount = this.$store.state.instanceConfig.notApprovedAmount;
      this.ic.notApprovedNumber = this.$store.state.instanceConfig.notApprovedNumber;
      this.ic.notApprovedFirst = this.$store.state.instanceConfig.notApprovedFirst;
      this.ic.notApprovedButton = this.$store.state.instanceConfig.notApprovedButton;
      this.ic.overTimePOROVT = this.$store.state.instanceConfig.overTimePOROVT;
    },
    updateOverTime(payload) {
      this.ic.overTimeTitle = payload.title;
      this.ic.overTimeContent = payload.content;
      this.ic.overTimeButton = payload.button;
    },
    async save() {
      window.scrollTo(0, 0);
      await this.saveInstanceConfig(this.ic);
    },
    async revert() {
      await this.fetchPaymentPortalInstanceConfig(this.$route.params.id);
      this.loadView(this.$route.params.id);
    }
  },
  computed: {
    ...mapState(["parrngStrategies"])
  }
};
</script>
